'use client';
/**
 * LatestUpates currently copies code from here
 * A refactor would be needed to be able to extract common logic from this and LatestUpates
 */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';

import { Folder, Item, Tab } from '../../types';
import { TABS } from '../../constants';
import { Listing, LoadingWrapper, StyledSpinner } from '../common';
import Breadcrumbs from '../Breadcrumbs';
import { CurrentUserContext } from '../../contexts';
import ItemInList from '../ItemInList';
import FolderInList from '../FolderInList';
import { useTranslations } from 'next-intl';
import { BreadcrumbsProps } from 'src/uiTypes';
import { notFound } from 'next/navigation';
import { useIsOffline } from 'src/hooks/useIsOffline';

const PreviewNotice = styled.p`
  color: darkred;
`;

const Intros = styled.div`
  margin-bottom: 2em;
`;

const DescriptionToggle = styled.button`
  background: none;
  color: #000;
  font-weight: 700;
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

function ListView({
  tab,
  folderSlug,
  hasEditPrivilege,
  loadedFolder,
  loadedItems,
  foldersForTab = [],
  visibleFoldersForTab = [],
}: {
  tab: Tab;
  folderSlug?: string;
  hasEditPrivilege: boolean;
  loadedFolder?: Folder | null;
  loadedItems: Array<Item> | null;
  foldersForTab: Array<Folder>;
  visibleFoldersForTab: Array<Folder>;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isDescriptionVisible, setIsDescriptionVisible] =
    useState<boolean>(false);
  const [folders, setFolders] = useState<Array<Folder> | null>(null);
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
  const [items, setItems] = useState<Array<Item> | null>(null);
  const [exists, setExists] = useState<boolean>(true);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [breadcrumbsProps, setBreadcrumbsProps] =
    useState<BreadcrumbsProps | null>(null);
  const currentUser = useContext(CurrentUserContext);
  const t = useTranslations();
  const { isOffline } = useIsOffline();

  const toggleDescription = useCallback(() => {
    setIsDescriptionVisible(!isDescriptionVisible);
  }, [isDescriptionVisible]);

  const fetchFoldersOrItems = useCallback(async () => {
    if (!currentUser.authLoaded) {
      return false;
    }

    // Check tab exists
    if (
      !tab ||
      !Object.values(TABS)
        .map((tabObj) => tabObj.slug)
        .includes(tab)
    ) {
      setExists(false);
      setLoading(false);
      return;
    }
    try {
      if (folderSlug && !loadedFolder) {
        // Folder does not exist
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      const folderId: string | null = loadedFolder?.id || null;

      const isFolderPublic =
        loadedFolder &&
        loadedFolder.parent &&
        visibleFoldersForTab.some(
          (f) => f.id === (loadedFolder && loadedFolder.parent),
        );

      // Parent folder does not exist / is not public:
      if (
        !hasEditPrivilege &&
        loadedFolder &&
        loadedFolder.parent &&
        !isFolderPublic
      ) {
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      let foldersForPage;
      if (folderId) {
        setCurrentFolder(foldersForTab.find((f) => f.id === folderId) || null);
        foldersForPage = foldersForTab.filter(
          (folder) => folder.parent === folderId,
        );
      } else {
        foldersForPage = foldersForTab.filter((folder) => !folder.parent);
      }
      setFolders(foldersForPage);
      setExists(true);

      if (folderId && foldersForPage.length === 0) {
        // We only show items if there are no sub-folders (this behaviour matches the mobile app)
        setItems(loadedItems);
      } else {
        setItems(null);
      }
      setIsPreview(
        !!loadedFolder &&
          ((loadedFolder?.visibility &&
            loadedFolder?.visibility !== 'public') ||
            (!!loadedFolder.parent && !isFolderPublic)),
      );

      setBreadcrumbsProps({ tab, folderId, folders: foldersForTab });
    } catch (error) {
      console.error(error);
      setLoadingError(true);
    }

    setLoading(false);
  }, [
    tab,
    folderSlug,
    currentUser,
    loadedFolder,
    loadedItems,
    hasEditPrivilege,
    foldersForTab,
    visibleFoldersForTab,
  ]);

  useEffect(() => {
    fetchFoldersOrItems();
  }, [fetchFoldersOrItems]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  if (isOffline && !folders) {
    return <p>{t('listView.error.offline')}</p>;
  }

  if (!exists || !tab) {
    notFound();
  }

  if (loadingError) {
    return <p>{t('common.error.oopsSomethingWentWrong')}</p>;
  }

  let intro;
  let description;
  if (currentFolder) {
    intro = currentFolder?.intro;
    description = currentFolder?.description;
  } else {
    const translatedTabIntro = t(
      `breadcrumbs.${TABS[tab].i18nKey}.description`,
    );
    intro = `<p>${translatedTabIntro}</p>`;
  }
  const toggleText = isDescriptionVisible
    ? t('listView.hideDescription')
    : t('listView.showDescription');

  return (
    <>
      {breadcrumbsProps && <Breadcrumbs {...breadcrumbsProps} />}
      <h1>
        {currentFolder
          ? currentFolder.title
          : t(`breadcrumbs.${TABS[tab].i18nKey}.name`)}
      </h1>
      <Intros>
        {intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
        {description && isDescriptionVisible && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {description && (
          <DescriptionToggle onClick={toggleDescription}>
            {toggleText}
          </DescriptionToggle>
        )}
      </Intros>
      {isPreview && (
        <PreviewNotice>
          <span role="img" aria-label={t('listView.noticeLabel')}>
            ⚠️
          </span>
          {t('listView.noticeContentTeam')}
        </PreviewNotice>
      )}
      <Listing>
        {folders &&
          folders.length > 0 &&
          folders.map((folder) => (
            <FolderInList key={folder.id} folder={folder} />
          ))}
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <ItemInList key={item.id} item={item} showBreadcrumbs={false} />
          ))}
      </Listing>
    </>
  );
}

export default ListView;
