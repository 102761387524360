'use client';

import { useState, useEffect } from 'react';

export const useIsOffline = () => {
  const [isOffline, setIsOffline] = useState(
    typeof navigator !== 'undefined' ? !navigator.onLine : true,
  );

  const updateIsOffline = () => {
    setIsOffline(typeof navigator !== 'undefined' ? !navigator.onLine : true);
  };

  useEffect(() => {
    window.addEventListener('offline', updateIsOffline);
    window.addEventListener('online', updateIsOffline);

    return () => {
      window.removeEventListener('offline', updateIsOffline);
      window.removeEventListener('online', updateIsOffline);
    };
  }, []);

  return { isOffline };
};
