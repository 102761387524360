import styled from 'styled-components';
import { Link } from 'src/i18n/routing';
import { Folder } from '../types';
import { TABS } from '../constants';
import { useTranslations } from 'next-intl';
import { Breadcrumb, BreadcrumbsProps } from 'src/uiTypes';
import { getFolderBreadcrumbs } from 'src/utils';

const BreadcrumbsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const Crumb = styled.li`
  display: inline-block;

  &::after {
    color: #777;
    font-size: small;
    content: '>';
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  &:last-child::after {
    content: '';
  }
`;

const StyledLink = styled(Link)`
  color: #777;
  font-size: small;
`;

type CustomBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export function CustomBreadcrumbs({ breadcrumbs }: CustomBreadcrumbsProps) {
  const t = useTranslations();

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink href={breadcrumb.path}>
            {t(`breadcrumbs.${breadcrumb.i18nKey}.name`)}
          </StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

export type FolderBreadcrumbsProps = {
  folderId: string | null | undefined;
  folders: Array<Folder>;
};

export function FolderBreadcrumbs({
  folderId,
  folders,
}: FolderBreadcrumbsProps) {
  const folderBreadcrumbs = getFolderBreadcrumbs(folders, folderId);

  return (
    <BreadcrumbsContainer>
      {folderBreadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink href={breadcrumb.path}>{breadcrumb.name}</StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

function Breadcrumbs({
  tab,
  folderId,
  folders,
  skipTopLevel = false,
  skipTab = false,
}: BreadcrumbsProps) {
  const t = useTranslations();
  const breadcrumbs = [];

  if (!skipTopLevel) {
    breadcrumbs.push({
      path: '/',
      i18nKey: 'plumVillage' as const,
    });
  }
  if (!skipTab) {
    breadcrumbs.push({
      path: `/${tab}`,
      i18nKey: TABS[tab].i18nKey,
    });
  }
  const folderBreadcrumbs = getFolderBreadcrumbs(folders, folderId);

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink href={breadcrumb.path}>
            {t(`breadcrumbs.${breadcrumb.i18nKey}.name`)}
          </StyledLink>
        </Crumb>
      ))}
      {folderBreadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink href={breadcrumb.path}>{breadcrumb.name}</StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

export default Breadcrumbs;
